/* eslint-disable no-param-reassign */
import { enc, mode, lib } from 'crypto-js';
import AES from 'crypto-js/aes';

export function encryptId(str) {
  const ciphertext = AES.encrypt(str, process.env.SECRET_ENCRYPT);
  return encodeURIComponent(ciphertext.toString());
}

export function decryptId(str) {
  const decodedStr = decodeURIComponent(str);
  return AES.decrypt(decodedStr, process.env.SECRET_ENCRYPT).toString(enc.Utf8);
}

export function getClient() {
  let client = `${process.env.SERVER_PASS}:${Date.now()}`
  let byteArray = new TextEncoder().encode(process.env.MASTER_KEY);
  let keyWordArray = lib.WordArray.create(byteArray);
  return AES.encrypt(client, keyWordArray, { mode: mode.ECB }).toString();
}

export function currencyFormatter(value) {
  if (typeof value !== 'number') value = 0.0;

  const defaultOptions = {
    significantDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbol: 'Rp',
  };

  let options = defaultOptions;

  options = { ...defaultOptions, ...options };
  value = value.toFixed(options.significantDigits);

  const [currency] = value.split('.');

  return `${options.symbol} ${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator,
  )}`;
}

export function truncate(str, n) {
  return str?.length > n ? `${str.substr(0, n - 1)}...` : str;
}

import { encryptId, decryptId } from '../helper/formatting';
import axios from 'axios';

export async function fetchTransactionData(code) {
    const response = await axios.get(`/api/invoice?ref=${encryptId(code)}`, {});
    return JSON.parse(decryptId(response.data));
}

export async function generatePayment(data) {
    const body = encryptId(JSON.stringify(data));
    const response = await axios({
        method: 'POST',
        url: '/api/payment',
        data: {ref: body},
    });
    return JSON.parse(decryptId(response.data));
}

export async function getPaymentData(reference_id) {
    const response = await axios.get(`/api/payment?ref=${encryptId(reference_id)}`, {});
    return JSON.parse(decryptId(response.data));
}

export async function cancelPayment(transactionId) {
    const response = await axios.put(`/api/cancel?ref=${encryptId(transactionId.toString())}`, {});
    return JSON.parse(decryptId(response.data));
}

export async function acceptPayment(invoiceId, TOKEN, confirmation) {
    await axios.get(`/api/accept?ref1=${encryptId(invoiceId.toString())}&ref2=${encryptId(TOKEN)}&ref3=${encryptId(confirmation.toString())}`);
}

export async function getVoucher(shopId) {
    const response = await axios.get(`/api/voucher?ref=${encryptId(shopId)}`);
    return JSON.parse(decryptId(response.data));
}

export async function getTrackingData(trackingId) {
    const response =  await axios.get(`/api/tracking?ref=${encryptId(trackingId.toString())}`, {});
    return JSON.parse(decryptId(response.data));
}

export async function login(phoneNumber) {
    const body = encryptId(JSON.stringify({
        phone: `62${phoneNumber}`,
    }));
    const response = await axios({
        method: 'POST',
        url: '/api/login?type=login',
        data: {ref: body},
    });
    return JSON.parse(decryptId(response.data));
}

export async function getLoginInfo(token) {
    const response = await axios({
        method: 'GET',
        url: '/api/login',
        headers: {
          authorization: encryptId(`Bearer ${token}`),
        },
    });
    return JSON.parse(decryptId(response.data));
}

export async function updateLoginInfo(mapAccounts, token) {
    const body = encryptId(JSON.stringify({
        accounts: mapAccounts,
      }));
    await axios({
        method: 'PUT',
        url: '/api/login',
        data: {ref: body},
        headers: {
          authorization: encryptId(`Bearer ${token}`),
        },
    });
    return JSON.parse(decryptId(response.data));
}

export async function verifyLogin(authyId, phoneNumber, mapOtp) {
    const body = encryptId(JSON.stringify({
        authyId: authyId,
        phone: `62${phoneNumber}`,
        otp: mapOtp,
    }));
    const response = await axios({
        method: 'POST',
        url: '/api/login?type=verify',
        data: {ref: body},
    });
    return JSON.parse(decryptId(response.data));
}

export async function createRecord(data, token) {
    const body = encryptId(JSON.stringify(data));
    await axios({
        method: 'POST',
        url: '/api/login?type=createRecord',
        data: {ref: body},
        headers: {
          authorization: encryptId(`Bearer ${token}`),
        },
    });
}